import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { LoadService } from 'src/app/core/services/load.service';
import { OfferService } from 'src/app/core/services/offer.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-excelcredit',
  templateUrl: './bc-conecta-excelcredit.component.html',
  styleUrls: ['./../bc-connect.component.scss', './bc-conecta-excelcredit.component.scss']
  //styleUrls: ['./../bc-connect.component.scss', './bc-conecta-excelcredit.component.scss']
})
export class BcConectaExcelcreditComponent implements OnInit {

  public resultContent: boolean = false;
  public success: boolean = true;
  public employmentStatus: string = '';
  public amountRequired: string = '';
  public correctAmount: boolean = false;
  public responseOK: any;
  public endCallPagadurias: boolean = false;

  estadoPension = [
    { value: true, texto: 'Pensionado' },
    { value: false, texto: 'No pensionado' }
  ];

  rangoEdades = [
    { id: 1, rango: 'Entre 40 años y 60 años' },
    { id: 2, rango: 'Entre 61 años y 79 años' },
    { id: 3, rango: 'Más de 80 años' }
  ];

  formStepA: boolean = true;
  formStepB: boolean = false;
  isInputActive: boolean = false;
  filtroBusqueda: string = '';
  fileName: string = '';
  docFileBase64: string = '';
  msgErrorFile: boolean = false;
  listFiltroPagadurias: any = [];
  listPagadurias: any = []
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  public textAprroved = 'EN PROCESO';
  activeModalProcessRequest: boolean = false;

  currentStep: number = 1;
  steps: number = 4;
  progress: number = 0;
  stepperformA: boolean = true;
  stepperformB: boolean = false;

  constructor(
    public sesion: SesionService,
    public load: LoadService,
    public utilities: UtilitiesService,
    public offer: OfferService,
    public broker: BrokerService,
    private cookieService: CookieService,
    public router: Router,
    private _formBuilder: FormBuilder
  ) {
    this.firstFormGroup = this._formBuilder.group({
      codEntidad: ['', [Validators.required, Validators.min(1)]],
      nomEntidad: ['', [Validators.required, Validators.minLength(1)]],
      monto: ['', [Validators.required, this.validarMonto()]],
      tel: [sesion.userPhone, [Validators.required, Validators.minLength(10), Validators.maxLength(10), this.phoneValidator()]],
    });
    this.secondFormGroup = this._formBuilder.group({
      rangoEdad: ['', Validators.required],
      inputFile: [this.docFileBase64, [Validators.required, this.fileValidator]],
      tyc: ['', Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    this.getListPagadurias();
  }

  showSuggestions(show: boolean, force: boolean = false): void {
    let boxSuggestions = document.getElementById('suggestions');
    if (boxSuggestions != undefined) {
      if (show) {
        boxSuggestions.style.display = 'block';
        this.isInputActive = true;
      } else {
        if (!boxSuggestions.matches(':hover')) {
          boxSuggestions.style.display = 'none';
          this.isInputActive = false;
        }
        if (force) {
          boxSuggestions.style.display = 'none';
          this.isInputActive = false;
        }
      }
    }
  }

  onBlurlist(event: any): void {
    // console.log((event.target as HTMLInputElement).value);
    this.isInputActive = false;
  }

  onInputChange(): void {
    this.isInputActive = true;
  }

  fileValidator(control: AbstractControl) {
    const file = control.value;
    if (!file) {
      return null;
    }
    const maxFileSize = 4 * 1024 * 1024;
    const validExtensions = ['application/pdf', 'image/jpeg', 'image/png'];
    if (file.size > maxFileSize) {
      return { fileTooLarge: true };
    }
    if (!validExtensions.includes(file.type)) {
      return { invalidFileType: true };
    }
    this.msgErrorFile = false;
    return null;
  }

  getListPagadurias() {
    this.endCallPagadurias = false;
    this.broker.getListPagadurias().subscribe({
      next: (result: any) => {
        this.listPagadurias = result;
        this.acortarNombres();
        if (this.listPagadurias.length > 0) {
          this.listFiltroPagadurias = this.listPagadurias;
        }
        this.endCallPagadurias = true;
      },
      error: () => {
        if (environment.envName == 'develop' || environment.envName == 'qa') {
          this.listPagadurias = [
            { id: "421", nombre: 'Sura' },
            { id: "422", nombre: 'Pagaduria' },
            { id: "423", nombre: 'Colpensiones' },
            { id: "424", nombre: 'Colpensiones seccion del sur' },
            { id: "425", nombre: 'Pagaduria 2' },
            { id: "426", nombre: 'Colpensiones 2' },
            { id: "427", nombre: 'Colpensiones seccion del sur 2' },
          ];
          this.acortarNombres();
          this.listFiltroPagadurias = this.listPagadurias;
        }
        this.endCallPagadurias = true;
        // this.listPagadurias = [];
      }
    });
  }

  filtroPagadurias(event: any) {
    // console.log(this.firstFormGroup.get('nomEntidad'));
    let filtroBusqueda = (event.target as HTMLInputElement).value;
    this.listFiltroPagadurias = this.listPagadurias.filter((p: { nombre: string; }) => p.nombre.toLowerCase().includes(filtroBusqueda.toLowerCase()));  // Filtrar y limitar a 4 resultados
    if (this.listFiltroPagadurias.length === 0) {
      this.listFiltroPagadurias = [];
    }
  }

  acortarNombres() {
    this.listPagadurias.map((ele: any) => {
      if (ele.nombre.length > 25) {
        ele['nombreReducido'] = ele.nombre.slice(0, 21) + '...';
      } else {
        ele['nombreReducido'] = ele.nombre;
      }
    })
  }

  setValuePensionado(target: EventTarget | null, status: boolean) {
    this.firstFormGroup.get('pensionado')?.setValue(status);
    let checkElement = (target as HTMLButtonElement);
    let checks = checkElement.parentElement?.children;
    if (checks != undefined)
      for (let index = 0; index < checks.length; index++) {
        const element = checks[index];
        element.classList.remove("active");

      }
    checkElement.classList.add("active");
    let val = checkElement.value;
    this.employmentStatus = val;
  }

  setValuePagaduria(data: any) {
    //console.log(data)
    this.firstFormGroup.get('codEntidad')?.setValue(data.id);
    this.firstFormGroup.get('nomEntidad')?.setValue(data.nombre);
    this.showSuggestions(false, true)
    // this.listFiltroPagadurias = [];
  }

  validPhone() {
    let pattern = /^(?!.*(\d)\1{3,})(^(3)\d*$)/;
    let isCorrectPattern = pattern.test(this.firstFormGroup.get('tel')?.value);
    let lengthPhone = (this.firstFormGroup.get('tel')?.value.length == 10);
    return isCorrectPattern && lengthPhone;
  }

  phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      let pattern = /^(?!.*(\d)\1{3,})(^(3)\d*$)/;
      let isCorrectPattern = pattern.test(value);
      let lengthPhone = value?.length === 10;
      return isCorrectPattern && lengthPhone ? null : { invalidPhone: true };
    };
  }

  checkDataFormA() {
    return this.firstFormGroup.valid;
  }
  checkDataFormB() {
    return this.secondFormGroup.valid;
  }

  returnStep() {
    this.formStepA = true;
    this.formStepB = false;
    this.fileName = '';
    this.nextStepper(true);
  }

  validateFirstForm() {
    if (this.firstFormGroup.valid) {
      this.formStepA = false;
      this.formStepB = true;
      this.nextStepper();
    }
  }

  nextStepper(back: boolean = false) {
    this.stepperformA = back;
    this.stepperformB = !back;
    this.progress = (back ? 0 : 100);
  }

  onFileSelected(event: Event): void {
    let input = event.target as HTMLInputElement;
    if (input?.files && input.files[0]) {
      const file = input.files[0];
      const maxFileSize = 4 * 1024 * 1024; // 4MB en bytes
      const validExtensions = ['application/pdf', 'image/jpeg', 'image/png'];
      if (!validExtensions.includes(file.type)) {
        this.msgErrorFile = true;
        this.secondFormGroup.controls['inputFile'].setErrors({ invalidFileType: true });
        input.value = '';
        this.fileName = '';
        return;
      }
      if (file.size > maxFileSize) {
        this.msgErrorFile = true;
        this.secondFormGroup.controls['inputFile'].setErrors({ fileTooLarge: true });
        input.value = '';
        this.fileName = '';
        return;
      }
      this.fileName = file.name;
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1];
        this.docFileBase64 = base64String;
        this.secondFormGroup.controls['inputFile'].setValue(this.docFileBase64);
        this.secondFormGroup.controls['inputFile'].setErrors(null);
      };
      reader.onerror = (error) => {
        console.error('Error al leer el archivo:', error);
      };
      this.msgErrorFile = false;
      reader.readAsDataURL(file);
    }
  }

  textInputNum(target: EventTarget | null) {
    let pattern = /[0-9]/;
    let textElement = (target as HTMLInputElement);
    let val = textElement.value;
    val = val.replace("$", "");
    val = val.split(".").join("");
    if (!pattern.test(val)) {
      textElement.value = "";
      this.amountRequired = "";
      this.correctAmount = false;
      return false;
    }
    if (val != "") {
      this.amountRequired = val;
      this.correctAmount = this.firstFormGroup.get('monto')?.valid ?? false;
      textElement.value = this.utilities.currency(val)
    }
    // this.firstFormGroup.get('monto')?.setValue(Number(val));
    return true;
  }

  validarMonto(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const montoStr = control.value;

      const convertirMonto = (monto: string): number | null => {
        if (monto.includes('$')) {
          monto = monto.split("$")[1].split('.').join('');
        }
        const numero = parseInt(monto, 10);
        return isNaN(numero) ? null : numero;
      };

      const validarRango = (monto: number, min: number, max: number): boolean => {
        return monto >= min && monto <= max;
      };

      // const validarMultiplo = (monto: number, multiplo: number): boolean => {
      //   return monto % multiplo === 0;
      // };
      let range = this.offer.offerSelected.detail.filters.find((ele: any) => ele.id == 3);
      let min = range.values.min;
      let max = range.values.max;
      // const multiplo = 5000;
      let monto = convertirMonto(montoStr);

      if (monto === null) {
        return { 'montoInvalido': true };
      }
      if (!validarRango(monto, min, max)) {
        return { 'rangoInvalido': true };
      }

      // if (!validarMultiplo(monto, multiplo)) {
      //   return { 'multiploInvalido': true };
      // }
      return null;
    };
  }


  async activeResult() {
    this.load.isInProcess = true;
    let companyId = this.offer.offerSelected.company.id;
    companyId = (companyId == null ? '' : companyId);
    let offerId = this.offer.offerSelected.offerId;
    let sourceOfTraffic = '';
    let campaignOfTraffic = '';
    let mediumOfTraffic = '';
    if ((Object.keys(this.utilities.utmParams).length > 0)) {
      for (const key in this.utilities.utmParams) {
        if (key.toLowerCase() == 'utm_source') {
          sourceOfTraffic = this.utilities.utmParams[key];
        }
        if (key.toLowerCase() == 'utm_medium') {
          mediumOfTraffic = this.utilities.utmParams[key];
        }
        if (key.toLowerCase() == 'utm_campaign') {
          campaignOfTraffic = this.utilities.utmParams[key];
        }
      }
    }
    let bodyUpdateProfile = {
      idSession: this.cookieService.get('IDSESSIONMDC'),
      cellPhone: this.firstFormGroup.get('tel')?.value,
      country: "CO"
    };
    let bodyOffer: any = {
      idSession: this.sesion.sesionCookie,
      companyId: companyId,
      amount: parseFloat(this.amountRequired),
      offerId: offerId,
      sourceOfTraffic: sourceOfTraffic,
      campaignOfTraffic: campaignOfTraffic,
      mediumOfTraffic: mediumOfTraffic
    };
    let conectaResponse = {
      "age2": this.secondFormGroup.get('rangoEdad')?.value,
      "idPagaduria": this.firstFormGroup.get('codEntidad')?.value,
      "acceptPTD": true,
      "acceptTyC": true,
      "base64File": this.docFileBase64
    };
    bodyOffer['conecta'] = conectaResponse;
    this.broker.updateInfo(bodyUpdateProfile).subscribe(
      (result: any) => {
        if (result.code == "200") {
          this.broker.aplicarOferta(bodyOffer).subscribe({
            next: (result: any) => {
              if (result.orderNumber && result.responseConecta != undefined) {
                if (result.responseConecta.response == "Pending") {
                  this.excelCreditFlow(result);
                  this.success = true;
                  this.activeModalProcessRequest = true;
                  this.formStepA = false;
                  this.formStepB = false;
                  this.resultContent = true;
                  this.load.isInProcess = false;
                  this.load.isConectaFail = false;
                  this.load.imgBcConectaDesktop = this.load.imgsBcConecta.d_success;
                  this.load.imgBcConectaMobile = this.load.imgsBcConecta.m_success;
                } else {
                  this.failProcess();
                }
              } else {

                this.failProcess();
              }
            },
            error: (error: any) => {
              this.failProcess();
            }
          }
          );
        }
        else {
          this.failProcess();
        }
      },
      (error: any) => {

        this.failProcess();
      });
  }

  /**
  * Funcion para mostrar los datos y hacer logica del flujo solventa
  * @param result el resulta del servicio de applyOffer
  */
  excelCreditFlow(result: any) {
    let offerAux = this.offer.offersList.find((ele: any) => ele.company.id == this.offer.offerSelected.company.id && ele.offerId == this.offer.offerSelected.offerId);
    offerAux.preselecta.orderNumber = result.orderNumber;
    offerAux.preselecta.date = result.applyDate;
    offerAux.preselecta.urlRedirect = result.applyDetail.urlRedirect;
    this.offer.offerSelected.isApplied = true;
    const urlRedirect = result.applyDetail.urlRedirect;
    if (urlRedirect != "" || urlRedirect != undefined) {
      this.responseOK = result.responseConecta;
    }
  }


  /**
  * Funcion para manejar el flujo de error/rechazo al moemento de aplicar una oferta en conecta
  * @param isRejected parametro para saber si la oferta fue rechazada o fallo otro consumo del servicio
  */
  failProcess(isRejected: boolean = false) {
    this.resultContent = true;
    this.activeModalProcessRequest = true;
    this.formStepA = false;
    this.formStepB = false;
    this.success = false;
    this.load.isInProcess = false;
    this.load.isConectaFail = true;
    this.load.isConectaRejected = isRejected;
    this.load.imgBcConectaDesktop = this.load.imgsBcConecta.d_fail;
    this.load.imgBcConectaMobile = this.load.imgsBcConecta.m_fail;
    if (isRejected) {
      setTimeout(() => {
        if (this.load.isConectaFail) {
          let categorySel = this.offer.categoriesActive.find((element: any) => { return element.id == this.offer.offerSelected.detail.categoryId });
          this.offer.offersList = this.offer.offersList.filter((ele: any) => ele.idOffer != this.offer.offerSelected.idOffer);
          this.offer.offersFil = this.offer.offersFil.filter((ele: any) => ele.idOffer != this.offer.offerSelected.idOffer);
          this.offer.offerSelected = undefined;
          this.load.isConectaFail = false;
          this.load.isConectaRejected = false;
          this.load.closeBcConecta();
          this.router.navigate(['/ofertas/' + categorySel.url]);
        }
      }, 15000);
    }
  }

  /**
  * Funcion para redireccionar al alidado
  */
  goAllie() {
    let offerAux = this.offer.offersList.find((ele: any) => ele.company.id == this.offer.offerSelected.company.id && ele.offerId == this.offer.offerSelected.offerId);
    window.open(offerAux.preselecta.urlRedirect, '_self');
    this.load.closeBcConecta();
  }

}

