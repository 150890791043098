import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { LoadService } from 'src/app/core/services/load.service';
import { OfferService } from 'src/app/core/services/offer.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';

@Component({
  selector: 'app-bc-connect',
  templateUrl: './bc-connect.component.html',
  styleUrls: ['./bc-connect.component.scss']
})
export class BcConnectComponent implements OnInit {


  public nitAliado:string = '';



  constructor(
    public sesion: SesionService, 
    public load: LoadService, 
    public utilities: UtilitiesService, 
    public offer: OfferService, 
    public broker: BrokerService, 
    public router:Router) { 
      this.nitAliado = this.offer.offerSelected.company.id;
    }

  ngOnInit(): void {

  }
}
