import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { content } from '../interface/content.interface';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
// import { SesionService } from './sesion.service';

const headers: any = new HttpHeaders({
	'Content-Type': 'application/json'
});

@Injectable({
	providedIn: 'root'
})
export class BrokerService {

	constructor(
		public http: HttpClient,
		private cookieService: CookieService) { }

	contenido_interface() {
		return this.http.get('assets/data/content.json');
	}

	getMenu() {
		return this.http.get(environment.enlaces_cloudFront.url + environment.enlaces_cloudFront.bcPath + environment.cloudFrontBc.categorias);
	}

	getFilters() {
		return this.http.get(environment.enlaces_cloudFront.url + environment.enlaces_cloudFront.bcPath + environment.cloudFrontBc.filter);
		// return this.http.get('assets/data/filter.json');
	}
	getNitPreselecta() {
		return this.http.get(environment.enlaces_cloudFront.url + environment.enlaces_cloudFront.bcPath + environment.cloudFrontBc.nitPreselecta);
	}

	//Session
	validar_sesion(body: any): any {
		return this.http.post(environment.APIEndpoint_authn + environment.Auth.validateSession, body, { headers });
	}

	cerrar_sesion(body: any) {
		return this.http.post(environment.APIEndpoint_authn + environment.Auth.logOff, body, { headers });
	}

	//Info User
	getIncomeInfo(): any {
		// const body = JSON.stringify([
		//   {
		//     "product": "BUSCACREDITO",
		//     "params": [
		//       {
		//         "name": "IN_SESSION",
		//         "value": this.cookieService.get('IDSESSIONMDC')
		//       }
		//     ]
		//   }
		// ]);
		const body = JSON.stringify({
			product: "BUSCACREDITO",
			sessionId: this.cookieService.get('IDSESSIONMDC')
		});
		return this.http.post(environment.APIEndpoint_ldnfosession + environment.InfoSession.ecs, body, { headers });
	}

	updateInfo(body: any): any {
		return this.http.post(environment.APIEndpoint_nwuser + environment.NewUser.updateProfile, body, { headers });
	}

	//Ofertas
	getOffers() {
		return this.http.get(environment.APIEndpoint_bank + environment.Bank.offersListHome, { headers });
	}

	getOffersFree() {
		return this.http.get(environment.APIEndpoint_bank + environment.Bank.offersListFree, { headers });
	}

	getOffersByID(body: any): Observable<any> {
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.offers, body, { headers });
	}
	getOffersPreselectaByID(body: any): Observable<any> {
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.offersPreselecta, body, { headers });
	}

	getListPagadurias(){
		return this.http.get(environment.APIEndpoint_bank + environment.Bank.pagadurias, { headers });
	}

	consultarDetalle(body: any) {
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.offerDetail, body, { headers });
	}

	consultarDetalleFree(body: any) {
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.offerDetailFree, body, { headers });
	}

	aplicarOferta(body: any) {
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.aplicarOferta, body, { headers });
	}
	applyOfferPreselecta(body: any) {
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.applyOfferPreselecta, body, { headers });
	}

	appliedUserOffer(body: any) {
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.appliedUserOffer, body, { headers });
	}

	saveOfferEvent(body: any) {
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.saveOfferEvent, body, { headers });
	}

	sendEncriptedData(body: any, url: any) {
		let urlVerify = this.verifyUrl(url)		
		return this.http.get(urlVerify, body).subscribe();
	}

	verifyUrl(url: string){
		if(url.includes('http')){
			return url;
		}
		return environment.APIEndpoint_bank;
	}

	// Nuevos Términos y Condiciones
	guardarTerminos(body: any) {
		const headers: any = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.post(environment.APIEndpoint_nwuser + environment.NewUser.addCustomerLog, body, { headers });
	}
	commercialBanners() {
		return this.http.get(environment.APIEndpoint_bank + environment.banners.getBanner);
	}
	historial_pedidos() {
		const body = JSON.stringify({
			idSession: this.cookieService.get('IDSESSIONMDC'),
			country: environment.country
		});
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.offerHistory, body, { headers });
	}

	getHistoryAppliedOffers(body: any) {
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.historyAppliedOffer, body, { headers });
	}

	setExperienceEvaluation(body: any) {
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.experienceEvaluation, body, { headers });
	}

	/*** EndPoint especial para la aplicación de la oferta de Davivienda ***/
	aplicarOfertaDav(body: any) {
		return this.http.post(environment.APIEndpoint_bank + environment.Bank.aplicarOfertaDav, body, { headers });
	}

	getContentBlog(){
		return this.http.get('assets/data/posts.json');
	}
}