<div class="modal-general">
    <div class="container" *ngIf="!activeModalProcessRequest">
        <div class="row stepper-container">
            <div class="col-2"></div>
            <div class="col-8">
              <div class="container-stteper">
                <div class="steps">
                  <div class="d-flex flex-column d-flex align-items-center ">
                    <div class="box-stepper-text-left">
                      <span class="circle circle-left" [class.active]="stepperformA"></span>
                    </div>
                    <div class="box-stepper-text-left">
                      <span class="stepper-text" [class.active-text]="stepperformA">PASO 1</span>
                    </div>
                    
                  </div>
                  <div class="d-flex flex-column  d-flex align-items-center">
                    <div class="box-stepper-text-right">
                      <span class="circle circle-right" [class.active]="stepperformB"></span>
                    </div>
                    <div class="box-stepper-text-right">
                      <span class="stepper-text" [class.active-text]="stepperformB">PASO 2</span>
                    </div>
                  </div>
                  <div class="progress-bar">
                    <span class="indicator" [ngStyle]="{ 'width': progress + '%' }"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
    <div class="container" *ngIf="formStepA" >
        <form [formGroup]="firstFormGroup" >
            <div class="row">
                <div class="col-12">
                    <h2>
                        Es necesaria la siguiente información.
                    </h2>
                    <p class="sub-title">
                        Ten en cuenta que quedará registrada en la aplicación con el aliado.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="">¿Con qué entidad te pensionaste?</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="text-center" *ngIf="!endCallPagadurias">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>                        
                    </div>
                    <div class="mb-10 custom-input-wrapper" *ngIf="endCallPagadurias">
                        <input type="text" 
                            placeholder="Selecciona una entidad" 
                            class="custom-input"
                            formControlName="nomEntidad"
                            [ngClass]="{'with-suggestion': isInputActive && listFiltroPagadurias.length >0 }"
                            (input)="filtroPagadurias($event)"
                            (focus)="showSuggestions(true)" 
                            (blur)="showSuggestions(false)"
                            >
                        <div id="suggestions" class="suggestions">
                            <ul class="autocomplete-list" *ngIf="listFiltroPagadurias.length >0">
                                <li 
                                    *ngFor="let pagaduria of listFiltroPagadurias" 
                                    (click)="setValuePagaduria(pagaduria)"
                                    >
                                    {{ pagaduria.nombreReducido }}
                                </li>
                            </ul>
                        </div>                
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="">¿Qué monto necesitas?</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">                    
                    <div class="input-container">
                        <input type="text" 
                            class="input"
                            placeholder="$0 COP" 
                            formControlName="monto"
                            (input)="textInputNum($event.target)">
                    </div>
                    <div *ngIf="!correctAmount && amountRequired != ''">
                        <p class="error">El monto propuesto se encuentra fuera del rango establecido por la entidad</p>
                    </div>
                </div> 
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="mb-0 mt-2">Tu número celular</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="mb-10">                        
                        <input 
                            type="tel" 
                            formControlName="tel"                             
                            [ngClass]="{'is-valid':validPhone()}" 
                            class="form-control input">
                    </div>
                </div>
            </div>            
            <div class="container">
                <div class="row">
                    <div class="col-12 section-btn">
                        <button class="cta-button secondary btn-right"  (click)="this.load.closeBcConecta()">Cancelar</button>        
                        <button class="cta-button btn-left" [disabled]="!checkDataFormA()" (click)="validateFirstForm()">Continuar<i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    
    <div class="container" *ngIf="formStepB">
        <form [formGroup]="secondFormGroup" >            
            <div class="row">
                <div class="col-12">
                    <h2>
                        Es necesaria la siguiente información.
                    </h2>
                    <p class="sub-title">
                        Ten en cuenta que esta quedará registrada en la aplicación con el aliado.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>Selecciona tu rango de edad:</p>                    
                </div>
            </div>
            <div class="row">
                <div class="col-12 custom-select-wrapper">                    
                    <select class="custom-select" formControlName="rangoEdad">
                    <option value="" disabled selected>Selecciona tu edad</option>
                    <option *ngFor="let edad of rangoEdades" [value]="edad.rango">{{ edad.rango }}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="mt-3">Adjunta tu último desprendible de pensión</p>                    
                </div>
            </div>
            <div class="row">
                <div class="col-12 upload-container">
                    <input  type="file" 
                        id="file" 
                        (change)="onFileSelected($event)" 
                        hidden accept=".pdf, .jpg, .png" 
                         />
                    <label for="file" class="upload-label">
                        <span>{{ fileName ? fileName.slice(0, 20)+"..." : 'Seleccione archivo' }}</span>
                        <i class="fa fa-plus-circle"></i>
                    </label>
                </div>
                <div class="col-12 sub-title-info" [ngClass]="{'text-danger': msgErrorFile}">
                    <span>FORMATOS PERMITIDOS:</span> archivo en formato PDF,JPG o PNG El tamaño del archivo no debe exceder los 4 MB.
                </div>
            </div>
            <div class="container">
                <div class="row check-div-info">
                    <div class="col-2 d-flex justify-content-center align-items-center p-0" >
                        <input type="checkbox" formControlName="tyc" aria-label="Checkbox for following text input" >
                    </div>
                    <div class="col-10 info-term-cond">
                        <span>He leído y acepto los <span class="font-weight-bold"><ins><a href="https://excelcredit.co/terminos" target="_blank" >TyC</a></ins></span> y autorizo el tratamiento de mis datos personales.</span>
                    </div>
                </div>            
            </div>            
            <div class="container">
                <div class="row mt-3 ">
                    <div class="col-12 section-btn">
                        <button class="cta-button secondary btn-right" (click)="returnStep()" >Regresar</button>
                        <button class="cta-button btn-left"  [disabled]="!checkDataFormB()" (click)="activeResult()" >Continuar<i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    
    <div class="container" *ngIf="resultContent">
        <div class="row">
            <div class="col-12">
                <h2 *ngIf="success" class="success">
                    ¡Buenas noticias, {{sesion.nameSession}}!
                </h2>
                <h2 *ngIf="!success" class="error">
                    ¡Ups! Algo salió mal.
                </h2>
                <p *ngIf="success" class="sub-title">
                    Tu <b>{{offer.offerSelected.card.productName}}</b> está casi listo para ser aprobado. 
                    <br/>
                    <br/>
                    Para continuar con el proceso de aplicación un asesor de <b>{{offer.offerSelected.company.name}}</b> se comunicará contigo en máximo 1 día hábil para darte información sobre el estado de tu aplicación.
                </p>
                <p *ngIf="!success" class="sub-title">
                    No pudimos procesar tu aplicación para la oferta seleccionada en este momento. Por favor, intenta nuevamente más tarde.
                </p>
                <!-- <p *ngIf="!success" class="sub-title">
                    Te invitamos a conocer más ofertas disponibles para ti.
                </p> -->
            </div>
            <div *ngIf="offer.offerSelected.detail.additionalBenefits && success" class="col-12">
                <div class="add-benefit">
                    <img title="crown" class="crown" alt="corona-beneficio" src="assets/icons/Icono_corona_adicional.svg" />
                    <div class="box-benefit">
                        <p class="descBenefit">{{offer.offerSelected.detail.textAdditionalBenefits}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="success" class="col-12 text-center">
                <button class="cta" (click)="goAllie()" >Entendido<i class="fa fa-chevron-right"
                        aria-hidden="true"></i></button>
            </div>
            <div *ngIf="!success" class="col-12 text-center">
                <button class="cta" (click)="load.closeBcConecta()">Entendido<i class="fa fa-chevron-right"
                        aria-hidden="true"></i></button>
            </div>
        </div>
    </div>    
</div>