import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatIconModule } from '@angular/material/icon';
// Componentes
import { CardComponent } from 'src/app/shared/card/card.component';
import { FiltersComponent } from 'src/app/shared/filters/filters.component';
// import { LoaderComponent } from 'src/app/shared/loader/loader.component';
import { LoadingComponent } from 'src/app/shared/loading/loading.component';
import { MicroLoaderComponent } from 'src/app/shared/micro-loader/micro-loader.component';
// import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { BcConnectComponent } from 'src/app/shared/bc-connect/bc-connect.component';
import { BannerGoogleAdsComponent } from 'src/app/shared/banner-google-ads/banner-google-ads.component';
import { BcConectaExcelcreditComponent } from 'src/app/shared/bc-connect/bc-conecta-excelcredit/bc-conecta-excelcredit.component';
import { BcConectaSolventaComponent } from 'src/app/shared/bc-connect/bc-conecta-solventa/bc-conecta-solventa.component';


@NgModule({
  declarations: [
    CardComponent,
    FiltersComponent,
    // LoaderComponent,
    LoadingComponent,
    MicroLoaderComponent,
    // ModalComponent,
    BannerGoogleAdsComponent,
    BcConnectComponent,
    BcConectaExcelcreditComponent,
    BcConectaSolventaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    RouterModule,
    RecaptchaModule,
    MatIconModule
  ],
  exports:[
    CardComponent,
    FiltersComponent,
    // LoaderComponent,
    LoadingComponent,
    MicroLoaderComponent,
    // ModalComponent,
    BannerGoogleAdsComponent,
    BcConnectComponent
  ]
})
export class SharedModule { }
